import React from "react";
import { Mdx } from "@runly/gatsby-theme";

import { Typography } from "@material-ui/core";

import DocLanguages from "../languages";
import CodeSample from "./code-sample";

import DocFooter from "./footer";

const DocArticle = ({ location, title, body }) => (
	<>
		<header>
			<Typography variant="h3" component="h1" gutterBottom>
				{title}
			</Typography>
		</header>

		<main role="main">
			<article>
				<Mdx components={{ DocLanguages, CodeSample }}>{body}</Mdx>
			</article>
		</main>

		<DocFooter location={location} />
	</>
);

export default DocArticle;
