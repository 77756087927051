import { useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { get } from "lodash";

const useNavData = location => {
	const { nav } = useStaticQuery(navDataQuery);

	const currentPath = get(location, "pathname");
	const isCurrent = useCallback(
		slug => (currentPath ? slug == currentPath : false),
		[currentPath]
	);
	const isActive = useCallback(
		slug => (currentPath ? currentPath.startsWith(slug) : false),
		[currentPath]
	);

	return { menu: nav.nodes, isCurrent, isActive };
};

const navDataQuery = graphql`
	query NavDataQuery {
		nav: allNavJson {
			nodes {
				title
				url
				subnav {
					title
					url
					subnav {
						title
						url
					}
				}
			}
		}
	}
`;

export default useNavData;
