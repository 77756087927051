import React, { Children } from "react";

import { CodeSample } from "@runly/gatsby-theme";

const DocsCodeSample = ({ children }) => {
	const langs = {};

	Children.forEach(children, (child, i) => {
		langs[translateIndex(i)] = child;
	});

	console.log(langs);

	return <CodeSample langs={langs} />;
};

const translateIndex = idx => {
	switch (idx) {
		case 0:
			return "csharp";
		case 1:
			return "javascript";
	}
};

export default DocsCodeSample;
