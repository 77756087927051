import React from "react";

import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "@runly/gatsby-theme";

import { ReactComponent as DotnetLogo } from "./dotnet.svg";
import { ReactComponent as NodejsLogo } from "./nodejs.svg";

const Languages = ({ dotnet, nodejs, maxHeight = 100, ...props }) => {
	const classes = useStyles({ maxHeight });

	return (
		<Box my={4} component="section" {...props}>
			<Grid container spacing={4} alignItems="center" justify="space-between">
				<Grid item xs={12} md={6}>
					<Link to={dotnet}>
						<DotnetLogo className={classes.logo} />
					</Link>
				</Grid>
				<Grid item xs={12} md={6}>
					<Link to={nodejs}>
						<NodejsLogo className={classes.logo} />
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
};

const useStyles = makeStyles({
	logo: {
		maxHeight: ({ maxHeight }) => maxHeight
	}
});

export default Languages;
