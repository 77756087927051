import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ReadingThemeProvider } from "../theme";

import Navbar from "./navbar";
import TableOfContents from "../toc";
import _DocArticle from "./article";

import { get } from "lodash";
import Layout from "../layout";
import Seo from "../seo";

const DocArticle = memo(_DocArticle);

const DocPage = ({
	location,
	data: {
		file: {
			childMdx: {
				frontmatter: { title, description, cover },
				body,
				excerpt,
				tableOfContents
			}
		}
	}
}) => {
	const classes = useStyles();

	return (
		<Layout location={location}>
			<Helmet titleTemplate="%s - Runly Documentation" />
			<Seo
				title={title}
				description={description || excerpt}
				article
				image={cover?.img.fixed.src}
			/>

			<ReadingThemeProvider>
				<Container>
					<Grid container spacing={6}>
						<Grid item xs={12} md={7} className={classes.gridContent}>
							<DocArticle location={location} title={title} body={body} />
						</Grid>

						<Grid item xs={12} md={3} className={classes.gridNav}>
							<Navbar location={location} className={classes.nav} />
						</Grid>

						<Grid item xs={12} md={2} className={classes.gridToc}>
							<TableOfContents
								toc={get(tableOfContents, "items", [])}
								className={classes.toc}
							/>
						</Grid>
					</Grid>
				</Container>
			</ReadingThemeProvider>
		</Layout>
	);
};

const useStyles = makeStyles(theme => ({
	gridNav: {
		order: 0,

		[theme.breakpoints.down("sm")]: {
			order: 2
		}
	},
	gridContent: {
		order: 1,
		"& img": {
			maxWidth: "100%"
		}
	},
	gridToc: {
		order: 2,

		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	nav: {},
	toc: {}
}));

export default DocPage;

export const pageQuery = graphql`
	query DocBySlug($slug: String!) {
		file(
			sourceInstanceName: { eq: "docs" }
			childMdx: { fields: { slug: { eq: $slug } } }
		) {
			childMdx {
				frontmatter {
					title
					description
					cover {
						img: childImageSharp {
							fixed(width: 800) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
				fields {
					slug
				}
				body
				excerpt
				tableOfContents(maxDepth: 2)
			}
		}
	}
`;
