import React from "react";
import { Link } from "@runly/gatsby-theme";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useNavData from "./nav-data";

const Navbar = ({ location, className }) => {
	const { menu, isCurrent, isActive } = useNavData(location);

	return (
		<Nav
			menu={menu}
			isCurrent={isCurrent}
			isActive={isActive}
			depth={0}
			className={className}
			aria-labelledby="nested-list-subheader"
		/>
	);
};

const Nav = ({ menu, isCurrent, isActive, className, depth, ...props }) => {
	if (!menu || !menu.length) {
		return null;
	}

	return (
		<List
			component={depth == 0 ? "nav" : "div"}
			className={className}
			{...props}
		>
			{menu.map(item => (
				<NavItem
					key={item.url || item.title}
					{...item}
					{...{ depth, isCurrent, isActive }}
				/>
			))}
		</List>
	);
};

const NavItem = ({ title, url, subnav, depth, isCurrent, isActive }) => {
	const classes = useStyles({ depth });

	return (
		<>
			{url ? (
				<ListItem
					button
					component={Link}
					color="inherit"
					underline="none"
					to={url}
					selected={isCurrent(url)}
					className={classes.navItem}
				>
					<ListItemText primary={title} />
				</ListItem>
			) : (
				<ListSubheader disableGutters>{title}</ListSubheader>
			)}

			{isActive(url) ? (
				<Nav
					menu={subnav}
					isCurrent={isCurrent}
					isActive={isActive}
					depth={depth + 1}
				/>
			) : null}
		</>
	);
};

export const useStyles = makeStyles(theme => ({
	navItem: ({ depth }) =>
		depth == 0
			? {}
			: {
					paddingLeft: theme.spacing(4 * depth)
			  }
}));

export default Navbar;
