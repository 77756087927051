import React, { useState, useCallback } from "react";

import { Typography, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const DocFooter = ({ location }) => {
	const [feedback, setFeedback] = useState(false);

	const onYesClick = useCallback(() => setFeedback("positive"), []);
	const onNoClick = useCallback(() => setFeedback("negative"), []);

	const classes = useStyles({ feedback });

	return (
		<footer className={classes.root}>
			<div className={classes.helpful}>
				<Typography className={classes.helpfulItem}>
					Was this page helpful?
				</Typography>

				<Button
					className={classes.helpfulItem}
					variant="outlined"
					onClick={onYesClick}
				>
					Yes
				</Button>
				<Button
					className={classes.helpfulItem}
					variant="outlined"
					onClick={onNoClick}
				>
					No
				</Button>
			</div>
			{/* Note: this form must be rendered statically (hidden via display) for netlify form handling to pick it up */}
			<div className={classes.feedback}>
				<form
					name="doc-feedback"
					method="POST"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
				>
					<input type="hidden" name="bot-field" />
					<input type="hidden" name="form-name" value="doc-feedback" />
					<input type="hidden" name="disposition" value={feedback} />
					<input type="hidden" name="pathname" value={location.pathname} />

					<TextField
						id="doc-feedback"
						name="feedback"
						label={
							feedback == "positive"
								? "What was most helpful?"
								: "How can we improve?"
						}
						required
						fullWidth
						multiline
						rows={8}
						className={classes.feedbackField}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						className={classes.feedbackSubmit}
					>
						Send Feedback
					</Button>
				</form>
			</div>
		</footer>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			borderTop: `solid 1px ${theme.palette.divider}`,
			marginTop: theme.spacing(3),
			paddingTop: theme.spacing(3)
		},
		helpful: {
			display: ({ feedback }) => (feedback ? "none" : "flex")
		},
		helpfulItem: {
			marginRight: theme.spacing(2)
		},
		feedback: {
			display: ({ feedback }) => (feedback ? "block" : "none")
		},
		feedbackField: {
			display: "block",
			marginBottom: theme.spacing(1)
		},
		feedbackSubmit: {
			marginTop: theme.spacing(2)
		}
	};
});

export default DocFooter;
