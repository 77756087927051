import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@runly/gatsby-theme";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";

import { flatMap } from "lodash";

const NavLink = props => <Link color="textPrimary" {...props} />;

const TableOfContents = ({ className, toc }) => {
	const classes = useStyles();

	let flatToc = useMemo(
		() => flatMap(toc, ({ items = [], ...item }) => [item, ...items]),
		[toc]
	);

	if (!toc || !toc.length) return null;

	return (
		<div className={`${classes.root} ${className || ""}`}>
			<List dense subheader={<ListSubheader>On this Page</ListSubheader>}>
				{flatToc.map(({ title, url }) => (
					<ListItem button key={url} component={NavLink} to={url}>
						<ListItemText primary={title} />
					</ListItem>
				))}
			</List>
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		"& a:active": {
			fontWeight: "bold"
		}
	},
	current: {
		"& > a": {
			fontWeight: "bold"
		}
	}
});

export default TableOfContents;
